import React, { useState } from 'react';
import { Send, Loader2, CheckCircle, AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function AdSection() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [message, setMessage] = useState('');

  const handleSubscribe = (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) return;

    try {
      setStatus('loading');
      setMessage('');

      // Format the email body
      const subject = encodeURIComponent('Newsletter Subscription Request');
      const body = encodeURIComponent(`
Hello,

A new user has requested to subscribe to the Tecowise AI newsletter.

Email: ${email}

Best regards,
Tecowise AI Platform
      `);

      // Open default email client with pre-filled content
      window.location.href = `mailto:contact@tecowise.com?subject=${subject}&body=${body}`;

      // Show success message
      setStatus('success');
      setMessage('Thank you for subscribing! Please send the email to complete your subscription.');
      setEmail('');

      // Reset success message after 5 seconds
      setTimeout(() => {
        setStatus('idle');
        setMessage('');
      }, 5000);
    } catch (error) {
      setStatus('error');
      setMessage('Failed to process subscription. Please try again.');
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {/* AI Tools Banner */}
      <div className="bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg p-6 text-white shadow-lg hover:shadow-xl transition-shadow">
        <h3 className="text-xl font-bold mb-2">AI Development Tools</h3>
        <p className="mb-4">Boost your productivity with our cutting-edge AI tools</p>
        <Link 
          to="/ai-tools"
          className="inline-block bg-white text-blue-600 px-4 py-2 rounded-md font-semibold hover:bg-blue-50 transition-colors"
        >
          Learn More
        </Link>
      </div>

      {/* AI Course Banner */}
      <div className="bg-gradient-to-r from-emerald-500 to-teal-600 rounded-lg p-6 text-white shadow-lg hover:shadow-xl transition-shadow">
        <h3 className="text-xl font-bold mb-2">AI Course Bundle</h3>
        <p className="mb-4">Master AI development with our comprehensive courses</p>
        <Link 
          to="/ai-course"
          className="inline-block bg-white text-emerald-600 px-4 py-2 rounded-md font-semibold hover:bg-emerald-50 transition-colors"
        >
          Start Learning
        </Link>
      </div>

      {/* Newsletter Banner */}
      <div className="bg-gradient-to-r from-rose-500 to-pink-600 rounded-lg p-6 text-white shadow-lg hover:shadow-xl transition-shadow">
        <h3 className="text-xl font-bold mb-2">AI Newsletter</h3>
        <p className="mb-4">Stay updated with the latest in AI technology</p>
        
        <form onSubmit={handleSubscribe} className="space-y-3">
          <div className="flex gap-2">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your email"
              className="flex-1 px-3 py-2 bg-white/10 rounded-md focus:outline-none focus:ring-2 focus:ring-white/50 text-sm placeholder-white/70"
              disabled={status === 'loading'}
              required
            />
            <button
              type="submit"
              disabled={status === 'loading' || !email}
              className="p-2 bg-white text-rose-600 rounded-md hover:bg-rose-50 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center min-w-[40px]"
            >
              {status === 'loading' ? (
                <Loader2 className="w-4 h-4 animate-spin" />
              ) : (
                <Send className="w-4 h-4" />
              )}
            </button>
          </div>

          {/* Status Messages */}
          {message && (
            <div className={`flex items-center text-sm ${
              status === 'success' ? 'text-white' : 'text-white/90'
            }`}>
              {status === 'success' ? (
                <CheckCircle className="w-4 h-4 mr-2 flex-shrink-0" />
              ) : status === 'error' ? (
                <AlertCircle className="w-4 h-4 mr-2 flex-shrink-0" />
              ) : null}
              <span>{message}</span>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}