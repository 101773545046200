import React from 'react';
import { Link } from 'react-router-dom';
import { generateSlug } from '../../utils/slugUtils';
import type { Article } from '../../types';

interface FeaturedGridProps {
  articles: Article[];
}

export default function FeaturedGrid({ articles }: FeaturedGridProps) {
  if (articles.length < 5) return null;

  const mainArticle = articles[0];
  const secondaryArticles = articles.slice(1, 5);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-12">
      {/* Main Featured Article */}
      <div className="lg:col-span-2">
        <Link
          to={`/article/${generateSlug(mainArticle.title)}`}
          className="group block"
        >
          {mainArticle.images?.[0] && (
            <div className="relative h-[400px] mb-4">
              <img
                src={mainArticle.images[0]}
                alt={mainArticle.title}
                className="w-full h-full object-cover rounded-lg"
              />
              <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black/80 to-transparent">
                <span className="text-blue-400 text-sm font-semibold mb-2 block">
                  {mainArticle.category}
                </span>
                <h2 className="text-3xl font-bold text-white group-hover:text-blue-400 transition-colors">
                  {mainArticle.title}
                </h2>
              </div>
            </div>
          )}
        </Link>
      </div>

      {/* Secondary Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4">
        {secondaryArticles.map((article) => (
          <Link
            key={article.id}
            to={`/article/${generateSlug(article.title)}`}
            className="group flex gap-4"
          >
            {article.images?.[0] && (
              <img
                src={article.images[0]}
                alt={article.title}
                className="w-24 h-24 object-cover rounded-lg flex-shrink-0"
              />
            )}
            <div>
              <span className="text-blue-600 text-sm font-medium mb-1 block">
                {article.category}
              </span>
              <h3 className="font-bold group-hover:text-blue-600 transition-colors line-clamp-2">
                {article.title}
              </h3>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}