import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Article } from '../types';

interface ArticleSEOProps {
  article: Article;
  baseUrl?: string;
}

export default function ArticleSEO({ 
  article, 
  baseUrl = 'https://tecowise.ai' 
}: ArticleSEOProps) {
  const url = `${baseUrl}/article/${article.title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '')}`;

  return (
    <Helmet>
      <title>{article.title} | Tecowise AI</title>
      <meta name="description" content={article.description} />
      <link rel="canonical" href={url} />

      {/* Article-specific Schema.org markup */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Article",
          "headline": article.title,
          "description": article.description,
          "image": article.images?.[0],
          "author": {
            "@type": "Person",
            "name": typeof article.author === 'string' ? article.author : article.author.name
          },
          "publisher": {
            "@type": "Organization",
            "name": "Tecowise AI",
            "logo": {
              "@type": "ImageObject",
              "url": `${baseUrl}/logo.png`
            }
          },
          "datePublished": article.created_at,
          "dateModified": article.updated_at,
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": url
          }
        })}
      </script>

      {/* Open Graph Tags */}
      <meta property="og:title" content={article.title} />
      <meta property="og:description" content={article.description} />
      <meta property="og:image" content={article.images?.[0]} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="article" />
      <meta property="article:published_time" content={article.created_at} />
      <meta property="article:modified_time" content={article.updated_at} />
      <meta property="article:section" content={article.category} />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={article.title} />
      <meta name="twitter:description" content={article.description} />
      <meta name="twitter:image" content={article.images?.[0]} />
    </Helmet>
  );
}