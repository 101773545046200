import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

interface AuthContextType {
  user: { id: string; email: string; role: string } | null;
  isAuthenticated: boolean;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<{ id: string; email: string; role: string } | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check if we have session data in localStorage
        const sessionStr = localStorage.getItem('tecowise_session');
        if (sessionStr) {
          const sessionData = JSON.parse(sessionStr);
          const { data: adminUser } = await supabase
            .from('adminuser')
            .select('*')
            .eq('adminemail', sessionData.email)
            .single();

          if (adminUser) {
            setUser({
              id: adminUser.id,
              email: adminUser.adminemail,
              role: 'admin'
            });
            setIsAuthenticated(true);
          } else {
            // Clear invalid session
            localStorage.removeItem('tecowise_session');
          }
        }
      } catch (error) {
        console.error('Session check error:', error);
        localStorage.removeItem('tecowise_session');
      } finally {
        setLoading(false);
      }
    };

    checkSession();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const { data: adminUser, error } = await supabase
        .from('adminuser')
        .select('*')
        .eq('adminemail', email)
        .single();

      if (error || !adminUser) {
        throw new Error('Invalid login credentials');
      }

      if (adminUser.adminpassword !== password) {
        throw new Error('Invalid login credentials');
      }

      const userData = {
        id: adminUser.id,
        email: adminUser.adminemail,
        role: 'admin'
      };

      // Store session data
      localStorage.setItem('tecowise_session', JSON.stringify(userData));

      setUser(userData);
      setIsAuthenticated(true);

    } catch (error) {
      console.error('Login error:', error);
      throw new Error('Invalid login credentials');
    }
  };

  const logout = async () => {
    try {
      localStorage.removeItem('tecowise_session');
      setUser(null);
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}