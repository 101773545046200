import React from 'react';
import { useArticles } from '../context/ArticleContext';
import { Loader2 } from 'lucide-react';
import TopStories from '../components/home/TopStories';
import CategorySection from '../components/home/CategorySection';
import LatestNews from '../components/home/LatestNews';
import FeaturedGrid from '../components/home/FeaturedGrid';
import QuickReads from '../components/home/QuickReads';
import AdSection from '../components/AdSection';

export default function HomePage() {
  const { articles, isLoading, error } = useArticles();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin text-blue-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-600 text-center p-4">
        Error loading articles: {error}
      </div>
    );
  }

  // Create a function to get unique articles by category
  const getUniqueArticlesByCategory = (category: string, count: number, excludeIds: Set<string>) => {
    const filtered = articles
      .filter(a => a.category === category && !excludeIds.has(a.id))
      .slice(0, count);
    filtered.forEach(article => excludeIds.add(article.id));
    return filtered;
  };

  // Keep track of used article IDs to avoid duplicates
  const usedArticleIds = new Set<string>();

  // Top Stories Section (5 articles)
  const topStories = articles
    .filter(a => !usedArticleIds.has(a.id))
    .slice(0, 5);
  topStories.forEach(article => usedArticleIds.add(article.id));

  // Featured Grid Section (5 articles)
  const featuredArticles = articles
    .filter(a => !usedArticleIds.has(a.id))
    .slice(0, 5);
  featuredArticles.forEach(article => usedArticleIds.add(article.id));

  // Quick Reads Section (8 articles)
  const quickReads = articles
    .filter(a => !usedArticleIds.has(a.id))
    .slice(0, 8);
  quickReads.forEach(article => usedArticleIds.add(article.id));

  // Category Sections (8 articles each = 32 articles)
  const aiNewsArticles = getUniqueArticlesByCategory("AI News", 8, usedArticleIds);
  const aiMoneyArticles = getUniqueArticlesByCategory("AI Money", 8, usedArticleIds);
  const aiToolsArticles = getUniqueArticlesByCategory("AI Tools", 8, usedArticleIds);
  const aiBlockchainArticles = getUniqueArticlesByCategory("AI Blockchain", 8, usedArticleIds);

  // Latest News Sidebar (10 articles)
  const latestArticles = articles
    .filter(a => !usedArticleIds.has(a.id))
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    .slice(0, 10);
  latestArticles.forEach(article => usedArticleIds.add(article.id));

  // Additional Stories (10 articles)
  const additionalStories = articles
    .filter(a => !usedArticleIds.has(a.id))
    .slice(0, 10);

  return (
    <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 mt-14">
      {/* Top Stories Section */}
      <TopStories articles={topStories} />

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Main Content Column */}
        <div className="lg:col-span-2">
          {/* Featured Grid */}
          <FeaturedGrid articles={featuredArticles} />
          
          {/* Quick Reads */}
          <QuickReads articles={quickReads} />

          {/* AI News Section with Ad */}
          <CategorySection
            title="AI News"
            articles={aiNewsArticles}
            viewAllLink="/ai-news"
          />
          <div className="mb-12">
            <AdSection />
          </div>

          {/* Remaining Category Sections */}
          <CategorySection
            title="AI Money"
            articles={aiMoneyArticles}
            viewAllLink="/ai-money"
          />

          <CategorySection
            title="AI Tools"
            articles={aiToolsArticles}
            viewAllLink="/ai-tools"
          />

          <CategorySection
            title="AI Blockchain"
            articles={aiBlockchainArticles}
            viewAllLink="/ai-blockchain"
          />
        </div>

        {/* Sidebar */}
        <div className="lg:col-span-1 space-y-8">
          {/* Latest News */}
          <LatestNews articles={latestArticles} />

          {/* Additional Stories */}
          <div className="bg-gray-50 rounded-xl p-6">
            <h2 className="text-xl font-bold mb-4">More Stories</h2>
            <div className="space-y-4">
              {additionalStories.map((article) => (
                <div key={article.id} className="border-b border-gray-200 pb-4 last:border-0">
                  <span className="text-blue-600 text-sm font-medium block mb-1">
                    {article.category}
                  </span>
                  <h3 className="font-bold hover:text-blue-600 transition-colors line-clamp-2">
                    {article.title}
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Second Ad Section at the bottom */}
      <div className="mt-12">
        <AdSection />
      </div>
    </div>
  );
}