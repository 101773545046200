import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Linkedin, Instagram, Send, Loader2, CheckCircle, AlertCircle } from 'lucide-react';

export default function Footer() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [message, setMessage] = useState('');

  const handleSubscribe = (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) return;

    try {
      setStatus('loading');
      setMessage('');

      // Format the email body
      const subject = encodeURIComponent('Newsletter Subscription Request');
      const body = encodeURIComponent(`
Hello,

A new user has requested to subscribe to the Tecowise AI newsletter.

Email: ${email}

Best regards,
Tecowise AI Platform
      `);

      // Open default email client with pre-filled content
      window.location.href = `mailto:contact@tecowise.com?subject=${subject}&body=${body}`;

      // Show success message
      setStatus('success');
      setMessage('Thank you for subscribing! Please send the email to complete your subscription.');
      setEmail('');

      // Reset success message after 5 seconds
      setTimeout(() => {
        setStatus('idle');
        setMessage('');
      }, 5000);
    } catch (error) {
      setStatus('error');
      setMessage('Failed to process subscription. Please try again.');
    }
  };

  return (
    <footer className="bg-gray-900 text-gray-300 mt-16">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Brand Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-bold bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
              Tecowise AI
            </h3>
            <p className="text-sm">
              Empowering the future through artificial intelligence. Stay ahead with the latest AI news, tools, and insights.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="font-semibold text-white mb-4">Quick Links</h4>
            <ul className="space-y-2">
              <li><Link to="/ai-news" className="hover:text-white transition">AI News</Link></li>
              <li><Link to="/ai-money" className="hover:text-white transition">AI Money</Link></li>
              <li><Link to="/ai-tools" className="hover:text-white transition">AI Tools</Link></li>
              <li><Link to="/ai-blockchain" className="hover:text-white transition">AI Blockchain</Link></li>
              <li><Link to="/ai-course" className="hover:text-white transition">AI Course</Link></li>
            </ul>
          </div>

          {/* Company */}
          <div>
            <h4 className="font-semibold text-white mb-4">Company</h4>
            <ul className="space-y-2">
              <li><Link to="/about" className="hover:text-white transition">About Us</Link></li>
              <li><Link to="/contact" className="hover:text-white transition">Contact</Link></li>
              <li><Link to="/privacy" className="hover:text-white transition">Privacy Policy</Link></li>
              <li><Link to="/terms" className="hover:text-white transition">Terms of Service</Link></li>
            </ul>
          </div>

          {/* Newsletter */}
          <div>
            <h4 className="font-semibold text-white mb-4">Newsletter</h4>
            <p className="text-sm mb-4">Subscribe to our newsletter for the latest AI updates.</p>
            <form onSubmit={handleSubscribe} className="space-y-4">
              <div className="flex gap-2">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your email"
                  className="flex-1 px-3 py-2 bg-gray-800 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  disabled={status === 'loading'}
                  required
                />
                <button
                  type="submit"
                  disabled={status === 'loading' || !email}
                  className="p-2 bg-blue-600 rounded-md hover:bg-blue-700 transition disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center min-w-[40px]"
                >
                  {status === 'loading' ? (
                    <Loader2 className="w-4 h-4 animate-spin" />
                  ) : (
                    <Send className="w-4 h-4" />
                  )}
                </button>
              </div>

              {/* Status Messages */}
              {message && (
                <div className={`flex items-center text-sm ${
                  status === 'success' ? 'text-green-400' : 'text-red-400'
                }`}>
                  {status === 'success' ? (
                    <CheckCircle className="w-4 h-4 mr-2 flex-shrink-0" />
                  ) : status === 'error' ? (
                    <AlertCircle className="w-4 h-4 mr-2 flex-shrink-0" />
                  ) : null}
                  <span>{message}</span>
                </div>
              )}
            </form>

            {/* Social Media */}
            <div className="mt-6">
              <div className="flex space-x-4">
                <a href="#" className="hover:text-white transition">
                  <Facebook className="w-5 h-5" />
                </a>
                <a href="#" className="hover:text-white transition">
                  <Twitter className="w-5 h-5" />
                </a>
                <a href="#" className="hover:text-white transition">
                  <Linkedin className="w-5 h-5" />
                </a>
                <a href="#" className="hover:text-white transition">
                  <Instagram className="w-5 h-5" />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-800 mt-12 pt-8 text-sm text-center">
          <p>© {new Date().getFullYear()} Tecowise AI. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}