import React from 'react';
import { useParams, Link, Navigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useArticle } from '../hooks/useArticle';
import { useArticles } from '../hooks/useArticles';
import ArticleSEO from '../components/ArticleSEO';
import ShareButtons from '../components/ShareButtons';
import LatestNews from '../components/LatestNews';
import { generateSlug } from '../utils/slugUtils';
import { sanitizeHtml } from '../utils/sanitizeHtml';
import { format } from 'date-fns';

export default function ArticlePage() {
  const { slug } = useParams<{ slug: string }>();
  const { article, relatedArticles, loading, error } = useArticle(slug);
  const { articles } = useArticles();
  const shareUrl = window.location.href;

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen px-4">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">Article Not Found</h1>
        <p className="text-gray-600 mb-6 text-center">{error}</p>
        <Link 
          to="/"
          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Return to Home
        </Link>
      </div>
    );
  }

  if (!article) {
    return <Navigate to="/404" replace />;
  }

  const canonicalSlug = generateSlug(article.title);
  if (slug !== canonicalSlug) {
    return <Navigate to={`/article/${canonicalSlug}`} replace />;
  }

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), "MMM dd, yyyy 'at' h:mm a 'EST'");
  };

  const sanitizedContent = sanitizeHtml(article.content);

  return (
    <>
      <ArticleSEO article={article} />
      <main className="flex-grow mt-14">
        <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <Link 
            to="/" 
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-8 group transition-colors"
          >
            <ArrowLeft className="w-5 h-5 mr-2 group-hover:-translate-x-1 transition-transform" />
            Back to Home
          </Link>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="lg:col-span-2">
              <article className="bg-white rounded-xl shadow-md overflow-hidden">
                <div className="p-8">
                  <div className="mb-6">
                    <span className="text-blue-600 font-medium">{article.category}</span>
                  </div>
                  
                  <h1 className="text-[2.5rem] font-serif font-bold leading-tight mb-4 text-gray-900 break-words">
                    {article.title}
                  </h1>
                  
                  <p className="text-xl text-gray-600 mb-6 font-serif">
                    {article.description}
                  </p>

                  <div className="flex items-center justify-between border-t border-b border-gray-200 py-4 mb-6">
                    <div className="flex items-center">
                      <span className="font-medium text-gray-900">By </span>
                      <span className="ml-1 text-gray-900">
                        {typeof article.author === 'string' ? article.author : article.author.name}
                      </span>
                      <span className="mx-2 text-gray-400">|</span>
                      <time className="text-gray-600" dateTime={article.created_at}>
                        {formatDate(article.created_at)}
                      </time>
                    </div>
                    
                    <ShareButtons 
                      url={shareUrl} 
                      title={article.title}
                    />
                  </div>

                  {article.images?.[0] && (
                    <div className="relative h-[400px] mb-8">
                      <img
                        src={article.images[0]}
                        alt={article.title}
                        className="w-full h-full object-cover rounded-lg"
                        loading="lazy"
                      />
                    </div>
                  )}

                  <div 
                    className="prose max-w-none font-serif text-lg"
                    dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                  />
                </div>
              </article>

              <div className="mt-12">
                <h2 className="text-2xl font-bold mb-6">Related Articles</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {relatedArticles.map((related) => (
                    <Link 
                      key={related.id}
                      to={`/article/${generateSlug(related.title)}`}
                      className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow group"
                    >
                      {related.images?.[0] && (
                        <div className="aspect-w-16 aspect-h-9">
                          <img
                            src={related.images[0]}
                            alt={related.title}
                            className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-105"
                            loading="lazy"
                          />
                        </div>
                      )}
                      <div className="p-4">
                        <div className="flex items-center justify-between mb-2">
                          <span className="text-sm font-medium text-blue-600">{related.category}</span>
                          <span className="text-sm text-gray-500">
                            {format(new Date(related.created_at), 'MMM dd, yyyy')}
                          </span>
                        </div>
                        <h3 className="font-bold text-lg mb-2 group-hover:text-blue-600 transition-colors line-clamp-2">
                          {related.title}
                        </h3>
                        <p className="text-gray-600 text-sm line-clamp-2">
                          {related.description}
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            <div className="lg:col-span-1 space-y-8">
              <LatestNews articles={articles} currentArticleId={article.id} />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}