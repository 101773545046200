import { Article } from '../types';

export const generateSlug = (title: string): string => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '')
    .trim();
};

export const generateArticleUrl = (article: Article): string => {
  const slug = generateSlug(article.title);
  return `/article/${slug}`;
};

export const generateSitemapUrl = (baseUrl: string, path: string): string => {
  return `${baseUrl.replace(/\/$/, '')}${path}`;
};