import React, { useState, useEffect, useRef } from 'react';
import { Search as SearchIcon, Loader2, AlertCircle } from 'lucide-react';
import SearchResults from './SearchResults';
import { useSearchData } from '../hooks/useSearchData';

export default function SearchBar() {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const searchRef = useRef<HTMLDivElement>(null);
  const { results, isLoading, error, search } = useSearchData();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (searchTerm.length >= 2) {
        search(searchTerm);
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [searchTerm, search]);

  return (
    <div ref={searchRef} className="absolute right-0 top-12 w-80 bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="p-2">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search articles..."
            className="w-full pl-10 pr-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            autoComplete="off"
          />
          <div className="absolute left-3 top-1/2 -translate-y-1/2">
            {isLoading ? (
              <Loader2 className="w-4 h-4 animate-spin text-gray-400" />
            ) : (
              <SearchIcon className="w-4 h-4 text-gray-400" />
            )}
          </div>
        </div>
      </div>

      {isOpen && searchTerm.length >= 2 && (
        <>
          {error ? (
            <div className="p-4 text-center">
              <AlertCircle className="w-6 h-6 text-red-500 mx-auto mb-2" />
              <p className="text-sm text-red-600">{error}</p>
            </div>
          ) : (
            <SearchResults 
              results={results} 
              onResultClick={() => {
                setIsOpen(false);
                setSearchTerm('');
              }} 
            />
          )}
        </>
      )}
    </div>
  );
}