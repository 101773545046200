import React from 'react';
import { Link } from 'react-router-dom';
import { SearchResult } from '../types';
import { FileText } from 'lucide-react';

interface SearchResultsProps {
  results: SearchResult[];
  onResultClick: () => void;
}

export default function SearchResults({ results, onResultClick }: SearchResultsProps) {
  if (results.length === 0) {
    return (
      <div className="px-4 py-3 text-sm text-gray-500 text-center border-t">
        No articles found
      </div>
    );
  }

  return (
    <div className="max-h-96 overflow-y-auto border-t divide-y">
      {results.map((result, index) => (
        <Link
          key={index}
          to={result.url}
          className="block px-4 py-3 hover:bg-gray-50 transition-colors"
          onClick={onResultClick}
        >
          <div className="flex items-start space-x-3">
            {result.image ? (
              <img
                src={result.image}
                alt={result.title}
                className="w-16 h-16 object-cover rounded-md flex-shrink-0"
              />
            ) : (
              <div className="w-16 h-16 bg-gray-100 rounded-md flex items-center justify-center flex-shrink-0">
                <FileText className="w-6 h-6 text-gray-400" />
              </div>
            )}
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900 truncate">
                {result.title}
              </p>
              <p className="text-xs text-blue-600 mb-1">{result.category}</p>
              {result.description && (
                <p className="text-xs text-gray-500 line-clamp-2">
                  {result.description}
                </p>
              )}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}