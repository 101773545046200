import { useState, useEffect, useCallback } from 'react';
import { supabase, withRetry } from '../lib/supabase';
import type { Article } from '../types';

export function useArticles() {
  const [articles, setArticles] = useState<Article[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchArticles = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      const { data, error: fetchError } = await withRetry(async () => 
        await supabase
          .from('articles')
          .select('*')
          .order('created_at', { ascending: false })
      );

      if (fetchError) throw fetchError;
      
      const formattedArticles = (data || []).map(article => ({
        id: article.id,
        title: article.title,
        author: article.author,
        category: article.category,
        description: article.description || '',
        content: article.content,
        read_time: article.read_time || '5 min',
        images: article.images || [],
        created_at: article.created_at,
        updated_at: article.updated_at
      }));

      setArticles(formattedArticles);
    } catch (err) {
      console.error('Error fetching articles:', err);
      setError(err instanceof Error ? err.message : 'Failed to fetch articles');
      setArticles([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchArticles();

    // Subscribe to realtime changes
    const subscription = supabase
      .channel('articles')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'articles' }, 
        () => {
          fetchArticles();
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [fetchArticles]);

  const refetchArticles = useCallback(async () => {
    await fetchArticles();
  }, [fetchArticles]);

  return { articles, isLoading, error, refetchArticles };
}