import React from 'react';
import { Link } from 'react-router-dom';
import { generateSlug } from '../../utils/slugUtils';
import type { Article } from '../../types';

interface TopStoriesProps {
  articles: Article[];
}

export default function TopStories({ articles }: TopStoriesProps) {
  const mainStory = articles[0];
  const secondaryStories = articles.slice(1, 5);

  if (!mainStory) return null;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
      {/* Main Story */}
      <Link 
        to={`/article/${generateSlug(mainStory.title)}`}
        className="group"
      >
        <div className="relative h-[400px] mb-4">
          {mainStory.images?.[0] && (
            <img
              src={mainStory.images[0]}
              alt={mainStory.title}
              className="w-full h-full object-cover rounded-lg"
            />
          )}
          <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black/80 to-transparent rounded-b-lg">
            <span className="text-blue-400 text-sm font-semibold mb-2 block">
              {mainStory.category}
            </span>
            <h2 className="text-3xl font-bold text-white mb-2 group-hover:text-blue-400 transition-colors">
              {mainStory.title}
            </h2>
            <p className="text-gray-200 line-clamp-2">{mainStory.description}</p>
          </div>
        </div>
      </Link>

      {/* Secondary Stories */}
      <div className="space-y-6">
        {secondaryStories.map((article) => (
          <Link
            key={article.id}
            to={`/article/${generateSlug(article.title)}`}
            className="flex gap-4 group"
          >
            {article.images?.[0] && (
              <img
                src={article.images[0]}
                alt={article.title}
                className="w-32 h-24 object-cover rounded-lg flex-shrink-0"
              />
            )}
            <div>
              <span className="text-blue-600 text-sm font-medium mb-1 block">
                {article.category}
              </span>
              <h3 className="font-bold text-lg mb-1 group-hover:text-blue-600 transition-colors">
                {article.title}
              </h3>
              <p className="text-gray-600 text-sm line-clamp-2">
                {article.description}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}