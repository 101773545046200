import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './context/AuthContext';
import { ArticleProvider } from './context/ArticleContext';
import { Navbar } from './components/Navbar';
import Footer from './components/Footer';
import OfflineNotice from './components/OfflineNotice';
import ErrorBoundary from './components/ErrorBoundary';
import ScrollToTop from './components/ScrollToTop';
import { Loader2 } from 'lucide-react';

// Eager load critical components
import HomePage from './pages/HomePage';
import ArticlePage from './pages/ArticlePage';

// Lazy load non-critical components
const AiNews = React.lazy(() => import('./pages/AiNews'));
const AiMoney = React.lazy(() => import('./pages/AiMoney'));
const AiTools = React.lazy(() => import('./pages/AiTools'));
const AiBlockchain = React.lazy(() => import('./pages/AiBlockchain'));
const AiCourse = React.lazy(() => import('./pages/AiCourse'));
const Login = React.lazy(() => import('./pages/Login'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const AllArticles = React.lazy(() => import('./pages/AllArticles'));
const AboutUs = React.lazy(() => import('./pages/AboutUs'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Privacy = React.lazy(() => import('./pages/Privacy'));
const Terms = React.lazy(() => import('./pages/Terms'));
const Dashboard = React.lazy(() => import('./pages/admin/Dashboard'));

const LoadingSpinner = () => (
  <div className="flex items-center justify-center min-h-screen">
    <Loader2 className="w-8 h-8 animate-spin text-blue-600" />
  </div>
);

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <ErrorBoundary>
        <Navbar />
        <div className="flex-grow">
          <React.Suspense fallback={<LoadingSpinner />}>
            {children}
          </React.Suspense>
        </div>
        <Footer />
        <OfflineNotice />
      </ErrorBoundary>
    </div>
  );
};

export default function App() {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <AuthProvider>
          <ArticleProvider>
            <Router>
              <ScrollToTop />
              <Routes>
                <Route path="/login" element={
                  <React.Suspense fallback={<LoadingSpinner />}>
                    <Login />
                  </React.Suspense>
                } />
                <Route path="/forgot-password" element={
                  <React.Suspense fallback={<LoadingSpinner />}>
                    <ForgotPassword />
                  </React.Suspense>
                } />
                <Route path="/admin/dashboard" element={
                  <React.Suspense fallback={<LoadingSpinner />}>
                    <Dashboard />
                  </React.Suspense>
                } />
                <Route path="/" element={
                  <Layout>
                    <HomePage />
                  </Layout>
                } />
                <Route path="/articles" element={
                  <Layout>
                    <AllArticles />
                  </Layout>
                } />
                <Route path="/ai-news" element={
                  <Layout>
                    <AiNews />
                  </Layout>
                } />
                <Route path="/ai-money" element={
                  <Layout>
                    <AiMoney />
                  </Layout>
                } />
                <Route path="/ai-tools" element={
                  <Layout>
                    <AiTools />
                  </Layout>
                } />
                <Route path="/ai-blockchain" element={
                  <Layout>
                    <AiBlockchain />
                  </Layout>
                } />
                <Route path="/ai-course" element={
                  <Layout>
                    <AiCourse />
                  </Layout>
                } />
                <Route path="/article/:slug" element={
                  <Layout>
                    <ArticlePage />
                  </Layout>
                } />
                <Route path="/about" element={
                  <Layout>
                    <AboutUs />
                  </Layout>
                } />
                <Route path="/contact" element={
                  <Layout>
                    <Contact />
                  </Layout>
                } />
                <Route path="/privacy" element={
                  <Layout>
                    <Privacy />
                  </Layout>
                } />
                <Route path="/terms" element={
                  <Layout>
                    <Terms />
                  </Layout>
                } />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Router>
          </ArticleProvider>
        </AuthProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
}