import React from 'react';
import { Copy, X } from 'lucide-react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  FacebookIcon,
  WhatsappIcon,
  RedditIcon,
  EmailIcon
} from 'react-share';

interface ShareButtonsProps {
  url: string;
  title: string;
}

export default function ShareButtons({ url, title }: ShareButtonsProps) {
  const [copied, setCopied] = React.useState(false);

  const handleCopyLink = async () => {
    try {
      const shareText = `${title} ${url} #TecowiseAI #AI #Technology via @TecowiseAI`;
      await navigator.clipboard.writeText(shareText);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const shareText = `${title} ${url} #TecowiseAI #AI #Technology via @TecowiseAI`;

  return (
    <div className="flex items-center space-x-2">
      <FacebookShareButton 
        url={url} 
        quote={shareText}
        hashtag="#TecowiseAI"
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton 
        url={url} 
        title={title}
        hashtags={['TecowiseAI', 'AI', 'Technology']}
        via="TecowiseAI"
        className="hover:opacity-80 transition-opacity"
      >
        <div className="bg-black rounded-full p-1.5">
          <X className="w-5 h-5 text-white" />
        </div>
      </TwitterShareButton>

      <WhatsappShareButton 
        url={url} 
        title={shareText}
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <RedditShareButton 
        url={url} 
        title={shareText}
      >
        <RedditIcon size={32} round />
      </RedditShareButton>

      <EmailShareButton 
        url={url} 
        subject={title}
        body={shareText}
      >
        <EmailIcon size={32} round />
      </EmailShareButton>

      <button
        onClick={handleCopyLink}
        className="relative p-1.5 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors"
        title="Copy link"
      >
        <Copy className="w-5 h-5 text-gray-600" />
        {copied && (
          <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black text-white text-xs px-2 py-1 rounded whitespace-nowrap">
            Copied!
          </span>
        )}
      </button>
    </div>
  );
}