import React from 'react';
import { Link } from 'react-router-dom';
import { Clock } from 'lucide-react';
import { generateSlug } from '../utils/slugUtils';
import type { Article } from '../types';

interface LatestNewsProps {
  articles: Article[];
  currentArticleId?: string;
}

export default function LatestNews({ articles, currentArticleId }: LatestNewsProps) {
  // Filter out current article and get latest 5 articles
  const latestArticles = articles
    .filter(article => article.id !== currentArticleId)
    .slice(0, 5);

  return (
    <div className="bg-gray-50 rounded-xl p-6">
      <div className="flex items-center mb-4">
        <Clock className="w-5 h-5 text-blue-600 mr-2" />
        <h2 className="text-xl font-bold">Latest Updates</h2>
      </div>
      <div className="space-y-4">
        {latestArticles.map((article) => (
          <Link
            key={article.id}
            to={`/article/${generateSlug(article.title)}`}
            className="block group"
          >
            <div className="flex items-start space-x-4">
              {article.images?.[0] && (
                <img
                  src={article.images[0]}
                  alt={article.title}
                  className="w-20 h-20 object-cover rounded-lg flex-shrink-0"
                  loading="lazy"
                />
              )}
              <div>
                <span className="text-sm text-blue-600 mb-1 block">
                  {article.category}
                </span>
                <h3 className="font-medium group-hover:text-blue-600 transition-colors line-clamp-2">
                  {article.title}
                </h3>
                <span className="text-sm text-gray-500">
                  {new Date(article.created_at).toLocaleDateString()}
                </span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}