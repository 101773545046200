import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import { generateSlug } from '../../utils/slugUtils';
import type { Article } from '../../types';

interface CategorySectionProps {
  title: string;
  articles: Article[];
  viewAllLink: string;
}

export default function CategorySection({ title, articles, viewAllLink }: CategorySectionProps) {
  if (articles.length === 0) return null;

  return (
    <section className="mb-12">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold text-gray-900">{title}</h2>
        <Link 
          to={viewAllLink}
          className="flex items-center text-blue-600 hover:text-blue-700 transition-colors"
        >
          View all
          <ChevronRight className="w-4 h-4 ml-1" />
        </Link>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {articles.map((article) => (
          <Link
            key={article.id}
            to={`/article/${generateSlug(article.title)}`}
            className="group"
          >
            {article.images?.[0] && (
              <img
                src={article.images[0]}
                alt={article.title}
                className="w-full h-48 object-cover rounded-lg mb-4"
              />
            )}
            <span className="text-blue-600 text-sm font-medium mb-1 block">
              {article.category}
            </span>
            <h3 className="font-bold text-lg mb-2 group-hover:text-blue-600 transition-colors line-clamp-2">
              {article.title}
            </h3>
            <p className="text-gray-600 text-sm line-clamp-3">
              {article.description}
            </p>
          </Link>
        ))}
      </div>
    </section>
  );
}