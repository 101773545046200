import React, { useState, useEffect } from 'react';
import { WifiOff, X } from 'lucide-react';

export default function OfflineNotice() {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => {
      setIsOffline(true);
      setIsVisible(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (!isOffline || !isVisible) return null;

  return (
    <div className="fixed bottom-4 right-4 bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-md shadow-lg animate-fade-in">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <WifiOff className="h-5 w-5 text-yellow-400 mr-2" />
          <p className="text-sm text-yellow-700">
            You are currently offline. Some content may not be up to date.
          </p>
        </div>
        <button
          onClick={() => setIsVisible(false)}
          className="ml-4 text-yellow-400 hover:text-yellow-500 focus:outline-none"
        >
          <X className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
}