import React from 'react';
import { Link } from 'react-router-dom';
import { generateSlug } from '../../utils/slugUtils';
import type { Article } from '../../types';

interface QuickReadsProps {
  articles: Article[];
}

export default function QuickReads({ articles }: QuickReadsProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-12">
      {articles.map((article) => (
        <Link
          key={article.id}
          to={`/article/${generateSlug(article.title)}`}
          className="group"
        >
          <span className="text-blue-600 text-sm font-medium mb-1 block">
            {article.category}
          </span>
          <h3 className="font-bold text-lg mb-2 group-hover:text-blue-600 transition-colors line-clamp-2">
            {article.title}
          </h3>
          <p className="text-gray-600 text-sm line-clamp-2">
            {article.description}
          </p>
        </Link>
      ))}
    </div>
  );
}