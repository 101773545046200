import { useState, useCallback } from 'react';
import { supabase } from '../lib/supabase';
import { SearchResult } from '../types';
import { generateSlug } from '../utils/slugUtils';

export function useSearchData() {
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const search = useCallback(async (term: string) => {
    if (term.length < 2) {
      setResults([]);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const searchTerm = term.toLowerCase();
      
      const { data: articles, error: searchError } = await supabase
        .from('articles')
        .select('*')
        .or(`title.ilike.%${searchTerm}%,category.ilike.%${searchTerm}%,description.ilike.%${searchTerm}%`)
        .order('created_at', { ascending: false })
        .limit(10);

      if (searchError) throw searchError;

      const searchResults: SearchResult[] = (articles || []).map(article => ({
        title: article.title,
        category: article.category,
        description: article.description,
        image: article.images?.[0],
        url: `/article/${generateSlug(article.title)}`
      }));

      setResults(searchResults);
    } catch (err) {
      console.error('Search error:', err);
      setError(err instanceof Error ? err.message : 'Failed to perform search');
      setResults([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { results, isLoading, error, search };
}