import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://tcsvfhdhzxnwbohwhueq.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRjc3ZmaGRoenhud2JvaHdodWVxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzA1MzkzODYsImV4cCI6MjA0NjExNTM4Nn0.OavAwR8-T0mXZnNMnrbx8dDdgzz8B6A9AKR7y2WrcvY';

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase configuration');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true
  },
  db: {
    schema: 'public'
  },
  global: {
    headers: {
      'x-application-name': 'tecowise-ai'
    }
  },
  realtime: {
    params: {
      eventsPerSecond: 10
    }
  }
});

// Add retry wrapper function with improved error handling
export async function withRetry<T>(
  operation: () => Promise<T>,
  maxRetries: number = 3,
  delay: number = 1000
): Promise<T> {
  let lastError: Error;
  
  for (let i = 0; i < maxRetries; i++) {
    try {
      return await operation();
    } catch (error) {
      lastError = error as Error;
      console.warn(`Retry attempt ${i + 1}/${maxRetries} failed:`, error);
      if (i < maxRetries - 1) {
        const backoffDelay = delay * Math.pow(2, i);
        console.log(`Retrying in ${backoffDelay}ms...`);
        await new Promise(resolve => setTimeout(resolve, backoffDelay));
      }
    }
  }
  
  console.error('All retry attempts failed:', lastError);
  throw lastError!;
}

// Add connection status check
export async function checkConnection(): Promise<boolean> {
  try {
    const { data, error } = await supabase.from('articles').select('count').limit(1);
    return !error && data !== null;
  } catch {
    return false;
  }
}